import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Chemical mechanical planarization tools`}</h4>
    <p>{`After other steps like etching and cleaning, chemical mechanical planarization (CMP) tools flatten the wafer surface. CMP tools are critical for chip fabrication, but not as complex as other tools, such as lithography and deposition tools.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      